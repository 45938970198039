<template>
  <div>
    <div v-if="notFound">
      <b-card class="text-center">
        <feather-icon
          size="80"
          icon="SearchIcon"
        />
        <div class="error mt-2">
          <h2 class="my-2">
            User not found
          </h2>
          <b-button
            variant="outline-primary"
            @click="() => $router.push({name: 'users'})"
          >
            Back to users
          </b-button>
        </div>
      </b-card>
    </div>
    <div v-else>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <div class="contact-box center-version">
              <div class="top">
                <b-avatar
                  size="100"
                  :src="viewData.profile_picture"
                  :text="avatarText(viewData.first_name)"
                  class="mb-2"
                />
                <h3 class="mb-2">
                  <strong> {{ viewData.name }} </strong>
                </h3>
                <h3
                  v-if="viewData.balance"
                  class="font-bold"
                >
                  Balance {{ viewData.balance }} £
                </h3>
              </div>
              <div class="user-info">
                <ul>
                  <li>
                    <feather-icon
                      icon="MailIcon"
                    /> Email: {{ viewData.email }}
                  </li>
                  <li v-if="viewData.gender">
                    <feather-icon
                      icon="UsersIcon"
                    /> Gender: {{ viewData.gender }}
                  </li>
                  <li>
                    <feather-icon
                      icon="ServerIcon"
                    /> Role: {{ resolveRoleName(viewData.role) }}
                  </li>
                  <li>
                    <feather-icon
                      icon="PhoneIcon"
                    /> Phone: {{ viewData.phone }}
                  </li>
                  <li>
                    <feather-icon
                      icon="PhoneIcon"
                    /> Birthdate: {{ viewData.birth_date || 'Empty' }}
                  </li>
                  <li>
                    <feather-icon
                      icon="PhoneIcon"
                    /> National ID: {{ viewData.national_id || 'Empty' }}
                  </li>
                  <li>
                    <feather-icon
                      icon="ActivityIcon"
                    /> Status:
                    <b-badge
                      class="change-status"
                      :variant="changeVaraint(viewData.active_type)"
                    >
                      {{ viewData.active_type }}
                    </b-badge>
                  </li>
                </ul>
              </div>
            </div>
          </b-card>
        </b-col>
        <meta-data
          :user-address="userAddresses"
          :user-files="userFiles"
          :options="options"
          add-component-name="add-address"
          edit-component-name="edit-address"
        />
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import user from '../users'
import MetaData from '@/components/MetaData.vue'

export default {
  components: { MetaData },
  setup() {
    const { viewData, resolveRoleName, resolveActiveUser } = user()
    const userAddresses = ref([])
    const userFiles = ref([])
    const loader = ref(false)
    const notFound = ref(false)

    const options = [
      { value: 'national_id', name: 'National ID' },
    ]

    return {
      viewData,
      resolveRoleName,
      resolveActiveUser,
      userAddresses,
      userFiles,
      options,
      avatarText,
      loader,
      notFound,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Accepted') return 'success'
      // eslint-disable-next-line eqeqeq
      if (type == 'Pending') return 'info'
      // eslint-disable-next-line eqeqeq
      if (type == 'Hold') return 'warning'

      return 'danger'
    },
    getdata() {
      this.loader = true
      axios.get(`users/${this.$route.params.id}`).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.viewData = res.data?.data
          this.userAddresses = res.data?.data.address
          this.userFiles = res.data?.data.files
        }
      }).catch(error => {
        if ((error.response.status === 400) || error.response.status === 404) {
          this.notFound = true
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}
</script>

<style lang="scss">
/* CONTACTS */
.contact-box {
  padding: 20px;
  margin-bottom: 20px;
}
.contact-box > a {
  color: inherit;
}
.contact-box.center-version {
  padding: 0;
}
.contact-box.center-version > .top {
  display: block;
  padding: 5px;
  text-align: center;
}
.contact-box.center-version > .top img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-box.center-version address {
  margin-bottom: 0;
}
.contact-box .contact-box-footer {
  border-top: 1px solid #e7eaec;
  padding: 15px 20px;
}
a{
    text-decoration:none !important;
}

.user-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
</style>
